import { Component, Vue, Watch } from 'vue-property-decorator'
import Psychosocial from '@/views/Patients/DossierDiab/Psychosocial/Psychosocial.vue'
import Infirmier from '@/views/Patients/DossierDiab/Infirmier/Infirmier.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'

@Component({
  components: {
    Confirm,
    Infirmier,
    Psychosocial
  }
})
export default class Diab extends Vue {
  public activeTabIndex = 0
  private isProgrammaticChange = false

  private anamneseHasChanged = false
  private suiviHasChanged = false

  public showConfirm = false
  public routeNextCallback = null as any
  public pendingNewTab = null as null | number

  @Watch('activeTabIndex')
  onActiveTabIndexChanged (newTabIndex: number, oldTabIndex: number) {
    const shouldRevert = this.shouldRevertToPreviousTab()
    if (!this.isProgrammaticChange && shouldRevert) {
      this.pendingNewTab = newTabIndex
      this.showConfirm = true
      this.isProgrammaticChange = true
      this.$nextTick(() => {
        this.activeTabIndex = oldTabIndex
        this.$nextTick(() => {
          this.isProgrammaticChange = false
        })
      })
    } else if (!shouldRevert) {
      this.pendingNewTab = null
    }
  }

  shouldRevertToPreviousTab () {
    return this.anamneseHasChanged || this.suiviHasChanged
  }

  beforeRouteLeave (to, from, next) {
    this.routeNextCallback = next
    if (this.anamneseHasChanged || this.suiviHasChanged) {
      this.showConfirm = true
      return false
    } else {
      next()
    }
  }

  public beforeUnloadHandler (e) {
    if (this.anamneseHasChanged || this.suiviHasChanged) {
      e.returnValue = 'Vos modifications ne sont peut-être pas enregistrées, voulez-vous vraiment quitter cette page ?'
    }
    return ''
  }

  public confirmCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      if (this.pendingNewTab != null) {
        this.isProgrammaticChange = true
        this.$nextTick(() => {
          this.activeTabIndex = this.pendingNewTab!
          this.pendingNewTab = null
          switch (this.activeTabIndex) {
            case 0:
              this.suiviHasChanged = false
              break
            case 1:
              this.anamneseHasChanged = false
              break
          }
          this.$nextTick(() => {
            this.isProgrammaticChange = false
          })
        })
      } else if (this.routeNextCallback) {
        this.routeNextCallback()
      }
    }
  }

  public beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  }

  public mounted () {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
  }

  public onAnamneseChanged () {
    this.anamneseHasChanged = true
  }

  public onAnamneseSaved () {
    this.anamneseHasChanged = false
  }

  public onSuiviChanged () {
    this.suiviHasChanged = true
  }

  public onSuiviSaved () {
    this.suiviHasChanged = false
  }
}
